.cursor {
  cursor: pointer;
}

.a-google {
  fill: #4285f4;
}

.b-google {
  fill: #34a853;
}

.c-google {
  fill: #fbbc05;
}

.d-google {
  fill: #eb4335;
}

.a-microsoft {
  fill: #f25022;
}

.b-microsoft {
  fill: #00a4ef;
}

.c-microsoft {
  fill: #7fba00;
}

.d-microsoft {
  fill: #ffb900;
}

.disable-component {
  display: none;
}

.table-responsive-parent:has(.dropdown-menu.dropdown-menu-right.show) {
  overflow-x: scroll;
  min-height: 250px;
}

.table-responsive-parent:has(.dropdown-menu.dropdown-menu-right.show) .table-responsive {
  overflow: visible;
}

.dropdown-menu * {
  text-decoration: none !important;
}

.border-secondary {
  border-color: #2E3192 !important;
}

/* navbar */
.nav-item .nav-link {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.navbar-main .navbar-nav.navbar-main-links .nav-item.focus {
  box-sizing: border-box !important;
  font-weight: 700 !important;
}

.navbar-main .navbar-nav.navbar-main-links .nav-item.focus * {
  color: #000;
}

.navbar-main .navbar-nav.navbar-main-links .nav-item.focus .nav-link:after {
  background-color: #ffc108;
}

@media (min-width: 992px) {
  .navbar-main .navbar-nav.navbar-main-links .nav-link:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    border-radius: 90px 90px 0 0;
    width: 100%;
    height: 3px;
  }
}

input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.show-active {
  position: relative;
  font-weight: bolder;
}

.show-active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #ffc107;
}

.remove-link-designs {
  text-decoration: none !important;
  /* Remove underline */
  color: inherit !important;
  /* Reset color */
  background-color: transparent !important;
  /* Reset background color */
  cursor: pointer !important;
  /* Add pointer cursor */
}

.text-primary-color {
  color: #2E3192;
}

.custom-button-color {
  background: #2E3192;
  color: white;
  font-weight: bold;
}

.custom-button-color:hover {
  color: white;
}

.secondary-button-color {
  color: #2E3192;
  border: 1px solid #5856D6;
}

.secondary-button-color:hover {
  color: #2E3192;
}

.alt-button-color {
  background: #DCDDFF;
  color: #2E3192
}

.custom-filter-form {
  background-color: #f2f7fa;
  box-shadow: rgb(236, 236, 236) 0 1px 6px inset;
}

.filter-form__custom-input {
  padding: 11px 9px !important;
}

.filter-form__custom-select input {
  padding: 0px !important;
  border: 0 !important;
}

.custom-select input {
  padding: 10px !important;
  border: 0 !important;
}

@keyframes rotate {
  from {
    transform: translate(0%, -50%) rotate(0deg);
  }

  to {
    transform: translate(0%, -50%) rotate(360deg);
  }
}

.fa-spinner {
  animation: rotate 2s linear infinite;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.7%;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
}

.detailed-info h6 {
  color: #9CABB8;
}

.detailed-card-info p {
  color: #9CABB8;
}

.detailed-card-info p>span {
  color: black;
}

.search {
  padding-left: 30px !important;
}

.page-item.active .page-link {
  background: #2e388f !important;
  border: 1px solid #2e388f !important;
  border-radius: 5px;
}

.page-link {
  border: none !important;
}

.page-item .page-link {
  color: #2e388f;
}

.image-container {
  margin-right: 10px;
}

.sidebar-nav .nav-item .nav-link {
  margin-left: 0 !important;
}

.img-border-radius {
  border-radius: 10px;
}

.sub-title {
  font-size: 0.8em;
  font-weight: normal;
}

.edit-btn-color,
.edit-btn-color:hover,
td i#dropdownMenu {
  color: #2E3192 !important
}

.dropdown-item {
  color: black !important
}

.dropdown-item:hover {
  background: #DCDDFF;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.details-heading {
  border-bottom: 5px solid #F2F2F2;
  padding-bottom: 16px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}


.text-wrapper-container,
.notification-heading {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}

.notification-heading {
  -webkit-line-clamp: 1;
  height: auto
}

.notification-description p {
  margin: 0;
}

.overflow-scrollY {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.overflow-scrollX {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
}

.text-danger {
  color: rgb(220, 53, 69) !important;
}

.select-option-modal__option {
  max-height: 300px !important;
}

.width-max-content {
  width: max-content !important;
}

.top-35 {
  top: 35% !important;
}

.end-5 {
  right: 5% !important;
}

/* needed to increase the specificity */
html #ecg-backoffice .custom-input-select__input input,
html #ecg-backoffice .custom-input-select__input input:focus {
  border: none !important;
  padding: 0 !important;
  height: 48px;
}

.status-badge {
  text-align: center;
  border: 1px solid;
  border-radius: 12px;
  font-size: 12px;
}

.status-badge.completed {
  background-color: #EDFAF7;
  border-color: #01C7B1;
  color: #01C7B1;
}

.status-badge.pending {
  background-color: #FFF5EC;
  border-color: #F6971C;
  color: #F6971C;
}

.status-badge.failed {
  background-color: #FFF0F2;
  border-color: #EC1A2E;
  color: #EC1A2E;
}

.rotate-upside-down {
  transform: rotate(180deg);
}

#scroll {
  background: white
}

.nav-tabs {
  border-bottom: 1px solid #e6eaed !important;
}

#reset-btn {
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  outline: none !important;
  border: transparent
}

#ecg-backoffice .input-no-border>input:focus {
  border: none !important;
  border-radius: 0px !important;
}

.none-pill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #5a6064;
  border: 1px solid #efeeee;
  border-radius: var(--FULL, 2000px);
  background: #f5f5f5;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}


.success-pill-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #009e96;
  border: 1px solid #009e96;
  border-radius: var(--FULL, 2000px);
  background: #ccf3ef;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.failed-pill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #cc2f26;
  border: 1px solid #e6eaed;
  border-radius: var(--FULL, 2000px);
  background: #ffd7d5;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.new-pill-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #0055B2;
  border: 1px solid #007AFF;
  border-radius: var(--FULL, 2000px);
  background: #E9F3FF;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.failed-pill-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #cc2f26;
  border: 1px solid #cc2f26;
  border-radius: var(--FULL, 2000px);
  background: #fef1f2;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.blue-pill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #0055b2;
  border: 1px solid #0055b2;
  border-radius: var(--FULL, 2000px);
  background: #e9f3ff;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pending-pill {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #f7961c;
  border: 1px solid #e6eaed;
  border-radius: var(--FULL, 2000px);
  background: #ffe9cc;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pending-pill-alt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 12px;

  color: #f7961c;
  border: 1px solid #f7961c;
  border-radius: var(--FULL, 2000px);
  background: #ffe9cc;

  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.max-index-backdrop {
  z-index: 9999;
}

.risk-indicator {
  display: flex;
  align-items: center;
}

.risk-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 0.3rem;
}

.high-risk-dot {
  background-color: #B22922;
}

.medium-risk-dot {
  background-color: orange;
}

.low-risk-dot {
  background-color: green;
}

.high-risk-text {
  color: #B22922;
}

.medium-risk-text {
  color: orange;
}

.low-risk-text {
  color: green;
}

.leaflet-container {
  width: 100%;
  height: 90vh;
}

@media (min-width: 992px) {
  .css-1nmdiq5-menu {
    margin-top: 30px !important;
  }
}

/* Staff Performance */
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.short-border-bottom::after {
  content: "";
  display: block;
  width: 100%;
  /* Adjust the width to shorten the border */
  margin: 15px auto 0 auto;
  /* Add space above with margin-top (10px in this case) */
  border-bottom: 1px solid #dee2e6 !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.bg-light-orange {
  background-color: #FFF5EB;
}

.bg-light-blue {
  background-color: #EBF5FF;
}

.no-active-border:active {
  border: none !important;
}

.rounded-start {
  border-top-left-radius: 8px;
}

.rounded-end {
  border-top-right-radius: 8px;
}

.contain-image {
  object-fit: contain;
  object-position: top left; 
}

.description-field .error-message {
  margin-top: 45px !important;
}